<template>
  <div class="relative ad-main-block">
    <div class="flex ad-container py-6">
      <div class="content flex gap-5">
        <div
          v-for="word in bannerwords"
          :key="word.k"
          class="flex flex-row px-10 custom-tab justify-center align-center"
        >
          <span class="font-semibold text-white">{{ word.name }}</span>
        </div>
      </div>
    </div>
    <div
      class="absolute right-0 cursor-pointer"
      style="top: -50px"
      @click="handleModal"
    >
      <img
        src="@/assets/images/landing/runningline.png"
        class="youtube-image"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  inject: ["openVideoModal"],
  methods: {
    handleModal() {
      return this.openVideoModal(
        "https://www.youtube.com/embed/QqhmhMaJVQ0?si=0NdUFSDmW3QmAemk"
      );
    },
  },
  data() {
    return {
      bannerwords: [
        { name: "Data Analysis on Spreadsheets, Data Science", k: 1 },
        { name: "Web Development", k: 2 },
        { name: "Graphic and UI/UX Design", k: 3,},
        { name: "3D Modeling and Animation", k: 4 },
        { name: "Basics of Cybersecurity", k: 5 },
        { name: "System Analysis & Design", k: 6 },
        { name: "Mobile Development on Flutter - старт курса - 20 февраля 2025", k: 7,},
      ],
    };
  },
};
</script>
<style scoped>
.ad-main-block {
  margin: 100px 0px;
  background-color: #5f6be4;
}
.ad-container {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  animation: runningLine 18s linear infinite;
}

.custom-tab {
  color: #fff;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1.5px solid #fba564;
}

.custom-tab:last-child {
  border-right: none;
  margin-right: 0;
}

@keyframes runningLine {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-60%);
  }
}

@media(max-width: 1450px) {
  .content {
    animation: runningLine 20s linear infinite;
  }
  @keyframes runningLine {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-75%);
    }
  }
}
@media(max-width: 1000px) {
  .content {
    animation: runningLine 22s linear infinite;
  }
  @keyframes runningLine {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-85%);
    }
  }
}

@media(max-width: 600px) {
  .content {
    animation: runningLine 28s linear infinite;
  }
  @keyframes runningLine {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-98%);
    }
  }
}

.custom-icon {
  height: 60px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.youtube-image {
  width: 180px;
  height: 180px;
}
</style>
