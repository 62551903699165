import { mapActions } from "vuex";
import { GET_ATTENDANCE_STATISTICS, GET_ATTENDANCE_STATISTICS_TABLE } from "@/store/attendance";

export default {
  data() {
    return {
      statistics: null,
      table_statistics: null,
      start_date: null,
      end_date: null,
    };
  },
  async mounted() {
    await this.getAttendanceStatistics();
    await this.getAttendanceStatisticsTable();
  },
  methods: {
    ...mapActions([GET_ATTENDANCE_STATISTICS, GET_ATTENDANCE_STATISTICS_TABLE]),
    async getAttendanceStatistics() {
      if (this.group) {
        const formattedStartDate = this.start_date ? this.start_date.toISOString().split('T')[0] : null;
        const formattedEndDate = this.end_date ? this.end_date.toISOString().split('T')[0] : null;
        this.statistics = await this[GET_ATTENDANCE_STATISTICS]({
          group_number: this.group,
          date_from: formattedStartDate,
          date_to: formattedEndDate,
        });
        this.chartData = this.generateChartData();
      }
    },
    async getAttendanceStatisticsTable() {
      if (this.group) {
        const formattedStartDate = this.start_date ? this.start_date.toISOString().split('T')[0] : null;
        const formattedEndDate = this.end_date ? this.end_date.toISOString().split('T')[0] : null;
        this.table_statistics = await this[GET_ATTENDANCE_STATISTICS_TABLE]({
          group_number: this.group,
          date_from: formattedStartDate,
          date_to: formattedEndDate,
        });
      }
    },
  },
  watch: {
    group: async function() {
      await this.getAttendanceStatistics();
      await this.getAttendanceStatisticsTable();
    },
    start_date: async function() {
      await this.getAttendanceStatistics();
      await this.getAttendanceStatisticsTable();
    },
    end_date: async function() {
      await this.getAttendanceStatistics();
      await this.getAttendanceStatisticsTable();
    },
  }
};

